<template>
<b-form-input
  v-model="getValue"
  v-currency="{currency: null, locale: 'en'}"
  v-bind="$attrs"
  class="form-control form-control-solid"
  @change="$emit('change', $event)"
>
  <template v-for="(_, slot) of $scopedSlots" #[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
</b-form-input>
</template>

<script>
export default {
  name: 'InputCurrency',
  props: {
    value: null,
  },
  computed: {
    getValue: {
      get() {
        if (this.value) {
          return this.value / 100
        } return this.value
      },
      set(v) {
        this.$emit('input', v ? v * 100 : v)
      },
    },
  },
}
</script>

<style scoped>

</style>

<!--<template>-->
  <!--<b-form-input-->
    <!--v-model="getValue"-->
    <!--v-bind="$attrs"-->
    <!--class="form-control form-control-solid"-->
    <!--@blur="isInputActive = false"-->
    <!--@focus="isInputActive = true"-->
    <!--v-on:change="$emit('change', $event)"-->
  <!--&gt;-->
    <!--<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>-->
  <!--</b-form-input>-->
<!--</template>-->

<!--<script>-->
  <!--export default {-->
    <!--name: "InputCurrency",-->
    <!--props: {-->
      <!--value: null,-->
      <!--delimiter: {-->
        <!--type: Number,-->
        <!--default: 2-->
      <!--}-->
    <!--},-->
    <!--data () {-->
      <!--return {-->
        <!--isInputActive: false-->
      <!--}-->
    <!--},-->
    <!--computed: {-->
      <!--getValue: {-->
        <!--get () {-->
          <!--if (this.isInputActive) {-->
            <!--return this.value.toString()-->
          <!--} else {-->
            <!--return this.value ? this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") : ''-->
          <!--}-->
        <!--},-->
        <!--set (v) {-->
          <!--let newValue = parseFloat(v.replace(/[^\d.]/g, ""))-->
          <!--if (isNaN(newValue)) {-->
            <!--newValue = 0-->
          <!--}-->
          <!--if (/^[0-9]+([.,]?\d{0,2})?$/.test(newValue)) this.$emit('input', newValue)-->
        <!--}-->
      <!--}-->
    <!--}-->
  <!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--</style>-->
